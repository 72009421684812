import React from "react";
import InputFormatCurrency from "./InputFormatCurrency";
import InputFormatKm from "./InputFormatKm";
import InputFormatPercentage from "./InputFormatPercentage";
import InputFormatYear from "./InputFormatYear";

const InputFormat = ({ type, ...rest }) => {
  if (type === "currency") {
    return <InputFormatCurrency {...rest} />;
  }

  if (type === "year") {
    return <InputFormatYear {...rest} />;
  }

  if (type === "km") {
    return <InputFormatKm {...rest} />;
  }

  if (type === "percentage") {
    return <InputFormatPercentage {...rest} />;
  }

  return <input {...rest} />;
};

export default InputFormat;
