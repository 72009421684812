import React, { useState } from "react";
import { Chip, makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import { getBrandAndModels } from "features/catalog/api/getBrandAndModels";

const useStyles = makeStyles({
  root: {
    "& .MuiAutocomplete-popupIndicatorOpen": {
      transform: "none"
    }
  },
  list: {
    margin: "0",
    padding: "8px 0",
    overflow: "auto",
    listStyle: "none",
    maxHeight: "40vh",
    "& .MuiAutocomplete-option, .MuiAutocomplete-groupLabel": {
      textTransform: "capitalize"
    }
  }
});

const SearchAutocomplete = ({ onSelect, className, label }) => {
  const classes = useStyles();
  const [data, setData] = useState([{ key: "" }]);
  const [value, setValue] = useState("");
  const [hasFetchedData, setHasFetchedData] = useState(false);

  const searchOnFocus = () => {
    if (hasFetchedData) {
      return;
    }

    getBrandAndModels().then(res => {
      const { aggregations } = res.data;
      const brandOptions = aggregations.brands.buckets.map(agg => ({
        key: agg.key,
        brand: agg.key,
        type: "marca"
      }));

      const modelOptions = aggregations.composite_brand_model.buckets.map(
        agg => ({
          key: agg.key.brand + " " + agg.key.model,
          model: agg.key.model,
          brand: agg.key.brand,
          type: "modelo"
        })
      );

      setData([...brandOptions, ...modelOptions]);
      setHasFetchedData(true);
    });
  };

  return (
    <Autocomplete
      autoComplete
      onFocus={searchOnFocus}
      openOnFocus={false}
      open={value.length >= 2}
      groupBy={option => option.type}
      inputValue={value}
      onChange={(_, value, reason) => {
        if (reason === "select-option") {
          onSelect(value);
        }
      }}
      disableClearable
      onInputChange={(_, value, reason) => {
        setValue(reason === "reset" ? "" : value);
      }}
      options={data}
      getOptionLabel={option => option.key.toLowerCase()}
      noOptionsText="Sem veículos com este nome."
      popupIcon={<SearchIcon />}
      ListboxProps={{
        className: classes.list
      }}
      id="marca_modelo"
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={option.key}
            variant="outlined"
            label={option.key}
            {...getTagProps({ index })}
          />
        ))
      }
      name="marca_modelo"
      clearOnBlur
      className={classes.root + " " + className}
      renderInput={params => (
        <TextField {...params} variant="filled" fullWidth label={label} />
      )}
    />
  );
};

export default SearchAutocomplete;
