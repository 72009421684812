import React from "react";
import Link from "next/link";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Grid, Typography } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import DesktopCarousel from "./DesktopCarousel";
import VehicleCardImage from "./VehicleCardImage";
import { Location } from "components/icon";

const useStyles = makeStyles(theme => ({
  details: {
    padding: "20px 20px 10px 20px"
  },
  location: {
    marginTop: "15px",
    paddingTop: "15px",
    borderTop: "1px solid #ddd",
    color: "#2E323C",
    "& svg": {
      marginRight: "4px"
    }
  },
  icon: {
    display: "inline-block",
    marginLeft: "-5px",
    marginRight: "10px"
  },
  title: {
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: "18px",
    lineHeight: "28px",
    marginBottom: "5px",
    color: "#2E323C"
  },
  version: {
    textTransform: "lowercase",
    lineHeight: "28px",
    color: "#40474F",
    height: "55px",
    "&::first-letter": {
      textTransform: "capitalize"
    }
  },
  link: {
    textDecoration: "none",
    "&:visited": {
      color: "transparent"
    }
  },
  price: {
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: "18px",
    margin: "15px 0px",
    color: "#2E323C"
  },
  yearVehicle: {
    "& p": {
      color: "#40474F"
    }
  },
  "@media (max-width: 599px)": {
    title: {
      fontSize: "14px",
      lineHeight: "14px"
    },
    link: {
      float: "left"
    },
    version: {
      fontSize: "12px",
      lineHeight: "17px",
      height: "auto"
    },
    price: {
      fontWeight: theme.typography.fontWeightSemiBold,
      fontSize: "16px",
      margin: "10px 0px 2px",
      lineHeight: "21px"
    },
    yearVehicle: {
      fontSize: "12px",
      lineHeight: "18px",
      color: "#40474F"
    },
    location: {
      borderTop: "none",
      fontSize: "12px",
      padding: "0",
      margin: "0",
      "& svg": {
        width: "15px",
        height: "14px"
      }
    },
    card: {
      padding: "10px 8px",
      minHeight: "132px",
      overflow: "hidden",
      display: "flex",
      alignItems: "center"
    },
    details: {
      padding: "4px 0 0 10px",
      width: "160px"
    },
    icon: {
      width: "18px",
      height: "17px"
    }
  },
  groupLabel: {
    backgroundColor: "black",
    height: "30px",
    position: "absolute",
    top: "0px",
    width: "100%",
    zIndex: "900000",
    color: "#ffffff",
    textAlign: "center",
    padding: "5px",
    fontSize: "16px",
    fontWeight: "500",
    borderRadius: "8px 8px 0px 0px"
  }
}));

const VehicleItemCard = ({ vehicle }) => {
  const classes = useStyles();
  const mobile = useMediaQuery("(max-width: 599px)");
  const imagePick = [
    "frente-passageiro",
    "frente-motorista",
    "painel-completo",
    "traseira-motorista"
  ];

  const sortFunc = (a, b) =>
    imagePick.indexOf(a.type) - imagePick.indexOf(b.type);

  const images = vehicle.images
    .filter(image => imagePick.includes(image.type))
    .sort(sortFunc);

  return (
    <Paper
      elevation={0}
      variant="outlined"
      className={classes.card}
      style={{ position: "relative" }}
    >
      {mobile && images.length ? (
        <VehicleCardImage
          href={`/veiculo/${vehicle.sku}`}
          sku={vehicle.sku}
          filename={images[0].filename}
          alt={images[0].title}
        />
      ) : (
        <DesktopCarousel
          href={`/veiculo/${vehicle.sku}`}
          sku={vehicle.sku}
          imageList={images}
        />
      )}
      <Link href={`/veiculo/${vehicle.sku}`}>
        <a className={classes.link}>
          <div className={classes.details}>
            <Typography variant="h5" className={classes.title} gutterBottom>
              {vehicle.brand.label} {vehicle.model.label}
            </Typography>
            <Typography
              className={classes.version}
              variant="body1"
              gutterBottom
            >
              {vehicle.version.label}
            </Typography>
            {mobile ? (
              <Typography variant="body2" className={classes.yearVehicle}>
                {vehicle.manufactureYear}/{vehicle.modelYear} |{" "}
                {vehicle.inspection.mileage.value} KM
              </Typography>
            ) : null}
            <Typography variant="body1" className={classes.price}>
              <strong>
                R${" "}
                {Math.floor(parseFloat(vehicle.max_price)).toLocaleString(
                  "pt-br"
                )}
              </strong>
            </Typography>
            {!mobile ? (
              <Grid
                container
                justifyContent="space-between"
                className={classes.yearVehicle}
              >
                <Grid item>
                  <Typography variant="body2">
                    {vehicle.manufactureYear}/{vehicle.modelYear}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" gutterBottom>
                    {vehicle.inspection.mileage.value} KM
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            <div className={classes.location}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item style={{ display: "flex" }}>
                  <Location className={classes.icon} />
                </Grid>
                <Grid item>{vehicle.location.city}</Grid>
              </Grid>
            </div>
          </div>
        </a>
      </Link>
    </Paper>
  );
};
export default VehicleItemCard;
