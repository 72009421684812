import React, { memo } from "react";
import { Typography } from "@material-ui/core";
import ControlDropdown from "components/ControlDropdown";
import DropdownCollapsable from "./DropdownCollapsable";

const ModelFilter = ({
  brandList = [],
  onChange,
  selectedBrand = [],
  selectedModel = []
}) => {
  const handleBrandChange = (value, checked, modelList) => {
    if (!checked) {
      if (!Array.isArray(selectedBrand)) {
        return onChange(["marca", "modelo"], [undefined, undefined]);
      }

      const updatedBrand = selectedBrand.filter(item => item !== value);
      const updatedModel = selectedModel.filter(
        item => !modelList.includes(item)
      );
      return onChange(["marca", "modelo"], [updatedBrand, updatedModel]);
    }

    let updatedBrand = [selectedBrand, value];

    if (Array.isArray(selectedBrand)) {
      updatedBrand = [...selectedBrand, value];
    }

    return onChange("marca", updatedBrand);
  };

  return (
    <ControlDropdown title="Marca e modelo" alwaysOpen size="big">
      {brandList.length === 0 ? (
        <Typography>Nenhum modelo disponível</Typography>
      ) : (
        brandList.map(brand => (
          <DropdownCollapsable
            key={brand.key}
            title={brand.key}
            data={brand.model.buckets}
            open={selectedBrand.includes(brand.key)}
            selectedCheck={selectedModel}
            onDropdownChange={handleBrandChange}
            onChange={onChange}
            name="modelo"
          />
        ))
      )}
    </ControlDropdown>
  );
};

export default memo(ModelFilter);
