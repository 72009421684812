import React from "react";
import NumberFormat from "react-number-format";

const InputFormatPercentage = ({
  inputRef,
  onChange,
  defaultValue,
  value,
  name,
  ...other
}) => {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      defaultValue={defaultValue}
      value={value}
      onValueChange={values => {
        onChange({
          target: {
            name: name,
            value: values.floatValue
          }
        });
      }}
      fixedDecimalScale
      thousandSeparator="."
      isNumericString
      decimalSeparator=","
      allowNegative={false}
      suffix="%"
    />
  );
};

export default InputFormatPercentage;
