import React from "react";
import { makeStyles } from "@material-ui/core";
import SearchAutocomplete from "./SearchAutocomplete";

const useStyles = makeStyles({
  inputBrand: {
    marginBottom: "20px",
    paddingRight: "8px",
    width: "100%",
    background: "#fff",
    position: "relative",
    borderRadius: "8px",
    "& input": {
      padding: "16px 0",
      "&::placeholder": {
        color: "#2E323C",
        opacity: "1"
      }
    },
    "& .MuiOutlinedInput-root": {
      height: "60px"
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #DFDFDF !important"
    }
  }
});

const SearchFieldFilter = ({ onChange }) => {
  const classes = useStyles();

  const onOptionSelected = ({ type, model, brand }) => {
    if (model) {
      return onChange([type, "marca"], [[model], [brand]]);
    }

    onChange(type, [brand]);
  };

  return (
    <SearchAutocomplete
      label="marca/modelo"
      onSelect={onOptionSelected}
      className={classes.inputBrand}
    />
  );
};

export default SearchFieldFilter;
