import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useRouter } from "next/router";
import OrderFilter from "./Filters/OrderFilter";

const useStyles = makeStyles(theme => ({
  orderBlock: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    background: "#F7F7F8",
    height: "80px",
    borderRadius: theme.shape.borderRadius,
    alignItems: "center",
    padding: "0 40px",
    marginBottom: "20px",
    "& p": {
      color: "#2E323C",
      fontWeight: theme.typography.fontWeightMedium
    }
  }
}));

const OrderHeader = ({ totalHits }) => {
  const classes = useStyles();
  const router = useRouter();
  const { query } = router;

  const handleOrderChange = ({ value }) => {
    query["ordenar"] = value;

    if (!value) {
      delete query["ordenar"];
    }

    router.push({ pathname: "/", query }, undefined, {
      shallow: true
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={3}></Grid>
      <Grid item xs={12} sm={12} md={9} lg={9}>
        <div className={classes.orderBlock}>
          <Typography variant="body1">
            {totalHits} veículos encontrados
          </Typography>
          <div>
            <OrderFilter
              onChange={handleOrderChange}
              order={query["ordenar"]}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default OrderHeader;
