import React from "react";
import { Fab, Grid, makeStyles, Modal } from "@material-ui/core";
import CatalogFiltersColumn from "./CatalogFiltersColumn";
import { useRouter } from "next/router";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#fff",
    height: "100%",
    overflow: "auto",
    padding: "0 20px",
    width: "100%"
  },
  modalHeader: {
    alignItems: "center",
    borderBottom: "1px solid #E9ECEE",
    display: "flex",
    height: "65px",
    justifyContent: "flex-end",
    marginBottom: "20px"
  },
  modalClearButton: {
    backgroundColor: "inherit",
    border: "none",
    color: theme.palette.primary,
    fontSize: "14px",
    fontWeight: 500,

    "&:hover": {
      cursor: "pointer"
    }
  },
  modalSearch: {
    marginBottom: "96px"
  },
  fabApplyFilters: {
    zIndex: 2,
    position: "fixed",
    height: "60px",
    bottom: "30px",
    left: "25%",
    padding: "24px",
    borderRadius: "10px"
  }
}));

const MobileFiltersModal = ({ isOpen, onMobile, onClose, filters }) => {
  const classes = useStyles();
  const router = useRouter();

  const clearFilters = () => {
    router.push("/", undefined, { shallow: true });
    onClose();
  };

  return (
    <Modal
      open={onMobile && isOpen}
      aria-labelledby="filter-modal"
      hideBackdrop={true}
      keepMounted={onMobile}
    >
      <>
        <Grid container className={classes.root}>
          <Grid item xs={12} className={classes.modalHeader}>
            <button className={classes.modalClearButton} onClick={clearFilters}>
              LIMPAR
            </button>
          </Grid>
          <Grid item xs={12} className={classes.modalSearch}>
            <CatalogFiltersColumn onMobile={onMobile} filters={filters} />
          </Grid>
          <Fab
            color="primary"
            variant="extended"
            aria-label="apply-filter"
            className={classes.fabApplyFilters}
            onClick={onClose}
          >
            Aplicar filtros
          </Fab>
        </Grid>
      </>
    </Modal>
  );
};

export default MobileFiltersModal;
