import React from "react";
import NumberFormat from "react-number-format";

const InputFormatYear = ({ inputRef, onChange, name, ...other }) => {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: name,
            value: values.value
          }
        });
      }}
      format="####"
      allowNegative={false}
      thousandSeparator=""
      isNumericString
    />
  );
};

export default InputFormatYear;
