import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";

const OrderFilter = ({ onChange, order }) => {
  const handleOrderChange = event => {
    event.persist();
    const { value } = event.target;

    onChange({
      name: "ordenar",
      value: value !== "score" ? value : undefined
    });
  };

  return (
    <FormControl variant="filled" fullWidth>
      <InputLabel htmlFor="ordernar">Ordenar:</InputLabel>
      <Select
        IconComponent={ExpandMore}
        labelId="ordernar"
        id="ordernar"
        value={order ?? "score"}
        onChange={handleOrderChange}
      >
        <MenuItem key={0} value="score">
          Mais recentes
        </MenuItem>
        <MenuItem key={1} value="asc">
          Por menor preço
        </MenuItem>
        <MenuItem key={2} value="desc">
          Por maior preço
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default OrderFilter;
