import React from "react";
import { useRouter } from "next/router";
import RangeFilter from "./Filters/RangeFilter";
import PlateFilter from "./Filters/PlateFilter";
import SearchFieldFilter from "./Filters/SearchFieldFilter";
import CleanFiltersDesktop from "./Filters/CleanFiltersDesktop";
import ControlDropdown from "components/ControlDropdown";
import CheckboxList from "./Filters/CheckboxList";
import SalesDeadlineFilter from "./Filters/SalesDeadlineFilter";
import ModelFilter from "./Filters/ModelFilter";

const generateQueryObject = (query, name, value) => {
  const queryObject = query;
  if (Array.isArray(name)) {
    name.forEach((item, i) => {
      queryObject[item] = value[i];
      if (value[i] === undefined) {
        delete queryObject[item];
      }
    });

    return query;
  }

  queryObject[name] = value;

  if (value === undefined) {
    delete queryObject[name];
  }

  return queryObject;
};

const CatalogFiltersColumn = ({ onMobile, filters }) => {
  const router = useRouter();
  const { query } = router;

  const handleQueryChange = (name, value) => {
    const updatedQueryObject = generateQueryObject(query, name, value);

    router.push({ pathname: "/", query: updatedQueryObject }, undefined, {
      shallow: true
    });
  };

  return (
    <>
      {onMobile ? null : <CleanFiltersDesktop />}
      <SearchFieldFilter onChange={handleQueryChange} />
      <SalesDeadlineFilter
        onChange={handleQueryChange}
        hasExpireToday={query.encerram_hoje}
        hasImmediateWithdrawal={query.retirada_imediata}
      />
      <ControlDropdown alwaysOpen title="Cidades">
        <CheckboxList
          selectedCheck={query.cidade}
          data={filters.city}
          onChange={handleQueryChange}
          name="cidade"
        />
      </ControlDropdown>
      <ModelFilter
        selectedBrand={query.marca}
        selectedModel={query.modelo}
        brandList={filters.brand}
        onChange={handleQueryChange}
      />
      <ControlDropdown title="Ano">
        <RangeFilter
          name="ano"
          type="year"
          currentRange={query.ano ?? []}
          rangeMin={filters.year_range?.min}
          rangeMax={filters.year_range?.max}
          onChange={handleQueryChange}
        />
      </ControlDropdown>
      <ControlDropdown title="Preço">
        <RangeFilter
          name="preco"
          type="currency"
          currentRange={query.preco ?? []}
          rangeMin={filters.price_range?.min}
          rangeMax={filters.price_range?.max}
          onChange={handleQueryChange}
        />
      </ControlDropdown>
      <ControlDropdown title="Quilometragem">
        <RangeFilter
          name="km"
          type="km"
          currentRange={query.km ?? []}
          rangeMin={filters.mileage_range?.min}
          rangeMax={filters.mileage_range?.max}
          onChange={handleQueryChange}
        />
      </ControlDropdown>
      <PlateFilter selectedCheck={query.placa} onChange={handleQueryChange} />
      <ControlDropdown title="Combustível">
        <CheckboxList
          selectedCheck={query.combustivel}
          data={filters.fuel}
          onChange={handleQueryChange}
          name="combustivel"
        />
      </ControlDropdown>
    </>
  );
};

export default CatalogFiltersColumn;
