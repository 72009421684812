import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CheckboxList from "./CheckboxList";
import { IconButton, Typography } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AddBoxIcon from "@material-ui/icons/AddBox";

const useStyles = makeStyles(theme => ({
  title: {
    lineHeight: "24px",
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    margin: "8px 0",
    "& h3": {
      fontSize: "14px",
      fontWeight: theme.typography.fontWeightMedium,
      margin: "0"
    }
  },
  tag: {
    display: "inline-flex",
    alignItems: "center",
    color: theme.palette.grey[800],
    borderRadius: "4px  4px 0 0",
    padding: "0 4px",
    width: "100%",
    transition: "background 0.1s ease-in"
  },
  selectedTag: {
    background: theme.palette.primary.dark,
    color: "white"
  }
}));

const DropdownCollapsable = ({
  data,
  title,
  name,
  onChange,
  onDropdownChange,
  filled,
  open,
  selectedCheck
}) => {
  const classes = useStyles();

  return (
    <div>
      <div
        className={`${classes.title} ${classes.tag} ${
          open ? classes.selectedTag : ""
        }`}
        onClick={() =>
          onDropdownChange(
            title,
            !open,
            data.map(item => item.key)
          )
        }
      >
        <Typography style={open ? { color: "white " } : null} variant="h3">
          {title}
        </Typography>
        <IconButton color="inherit" edge="start" size="small">
          {open ? <CheckBoxIcon /> : <AddBoxIcon />}
        </IconButton>
      </div>
      {open ? (
        <CheckboxList
          selectedCheck={selectedCheck}
          data={data}
          name={name}
          onChange={onChange}
          filled={filled}
        />
      ) : null}
    </div>
  );
};

export default DropdownCollapsable;
