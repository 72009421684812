import React from "react";
import CheckboxList from "./CheckboxList";
import ControlDropdown from "components/ControlDropdown";

const createSelectedCheck = (hasExpireToday, hasImmediateWithdrawal) => {
  const arr = [];

  if (hasExpireToday) {
    arr.push("encerram_hoje");
  }

  if (hasImmediateWithdrawal) {
    arr.push("retirada_imediata");
  }

  return arr;
};

const interestFilters = [
  {
    label: "Encerram hoje!",
    key: "encerram_hoje"
  },
  {
    label: "Retirada imediata",
    key: "retirada_imediata"
  }
];

const SalesDeadlineFilter = ({
  onChange,
  hasExpireToday,
  hasImmediateWithdrawal
}) => {
  const selectedCheck = createSelectedCheck(
    hasExpireToday,
    hasImmediateWithdrawal
  );

  const handleChangeInterest = (_, value, name) =>
    onChange(name, value.includes(name) || undefined);

  return (
    <ControlDropdown title="Oportunidades do dia" alwaysOpen>
      <CheckboxList
        data={interestFilters}
        selectedCheck={selectedCheck}
        onChange={handleChangeInterest}
        name="interest"
      />
    </ControlDropdown>
  );
};

export default SalesDeadlineFilter;
