import React from "react";
import Head from "next/head";
import CatalogPage from "features/catalog/pages/CatalogPage";

const Catalog = () => (
  <>
    <Head>
      <title>
        Carros seminovos com garantia de câmbio: compre seu carro na Loop
        Veículos
      </title>
      <meta
        name="description"
        content="Compre o seu carro com transparência, comodidade e rapidez na Loop Veículos. Melhores condições de financiamento do mercado."
      />
    </Head>
    <CatalogPage />
  </>
);

export default Catalog;
