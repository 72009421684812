import React, { useEffect, useRef } from "react";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { useRouter } from "next/router";
import Loading from "components/Loading";

const useStyles = makeStyles({
  paper: { marginTop: "40px", padding: "8px 64px" }
});

const InfiniteScroll = ({ isFetching, quantityOfHits }) => {
  const classes = useStyles();
  const loader = useRef(null);
  const router = useRouter();
  const { query, push } = router;
  const DEFAULT_VEHICLE_NUMBER = 9;

  const maxVehiclesReached =
    (quantityOfHits < query["numero_veiculos"] ||
      quantityOfHits < DEFAULT_VEHICLE_NUMBER) &&
    !isFetching &&
    quantityOfHits;

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0
    };
    const getMoreVehicles = entries => {
      const target = entries[0];

      if (maxVehiclesReached || !quantityOfHits) {
        return false;
      }

      if (target.isIntersecting) {
        const updatedQuery = query;
        if (!updatedQuery["numero_veiculos"]) {
          updatedQuery["numero_veiculos"] = 9;
        }
        updatedQuery["numero_veiculos"] =
          9 + Number(updatedQuery["numero_veiculos"]);

        push({ pathname: "/", query: updatedQuery }, undefined, {
          shallow: true
        });
      }
    };

    const observer = new IntersectionObserver(getMoreVehicles, option);
    if (loader.current) observer.observe(loader.current);
  }, [isFetching, maxVehiclesReached, quantityOfHits, query, push]);

  if (isFetching) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Paper variant="outlined" className={classes.paper}>
            <Typography variant="h3">
              <Loading />
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  if (maxVehiclesReached) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Paper variant="outlined" className={classes.paper}>
            <Typography variant="h3">
              Todos os veículos já foram mostrados
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <div ref={loader} />
    </Grid>
  );
};

export default InfiniteScroll;
