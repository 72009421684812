import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import ArrowDown from "@material-ui/icons/ExpandMore";
import ArrowUp from "@material-ui/icons/ExpandLess";

const useStyles = makeStyles(theme => ({
  filter: {
    paddingRight: "8px",
    "& > *": {
      display: "flex",
      flexDirection: "column",
      border: "1px solid #DFDFDF",
      borderRadius: theme.shape.borderRadius,
      marginBottom: "16px",
      padding: "16px",
      transition: "max-height .25s ease-in-out"
    }
  },
  title: {
    lineHeight: "24px",
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    marginBottom: "16px"
  },
  children: {
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "3px"
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent"
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#DFDFDF",
      borderRadius: "4px"
    }
  },
  "@media (max-width: 1024px)": {
    filter: {
      width: "100%",
      paddingRight: "0",
      "& > *": {
        width: "100%"
      }
    }
  }
}));

const ControlDropdown = ({ title, alwaysOpen, size, children }) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(alwaysOpen);
  const big = size === "big";

  return (
    <div className={classes.filter}>
      <Paper
        elevation={0}
        style={{
          maxHeight: visible ? (big ? "680px" : "180px") : "60px"
        }}
      >
        <div className={classes.title} onClick={() => setVisible(!visible)}>
          <Typography>{title}</Typography>
          {alwaysOpen ? null : visible ? (
            <ArrowUp className={classes.expand} />
          ) : (
            <ArrowDown className={classes.expand} />
          )}
        </div>
        <div className={classes.children}>{children}</div>
      </Paper>
    </div>
  );
};

export default ControlDropdown;
