import { instance } from "api";
import { Keys } from "constants/Keys";
import { useRouter } from "next/router";
import { stringify } from "query-string";
import { useQuery } from "react-query";

export const getVehicles = filters =>
  instance
    .get(`/search/santander${filters ? `?${filters}` : ""}`)
    .then(res => res.data);

export const useVehicles = () => {
  const router = useRouter();
  const { query } = router;

  const queryString = stringify(query, {
    arrayFormat: "bracket",
    parseBooleans: true
  });

  const catalog = useQuery(
    Keys.getCatalog(query),
    () => getVehicles(queryString),
    { keepPreviousData: true }
  );

  return catalog;
};
