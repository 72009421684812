import React from "react";
import { Footer, Header } from "components";
import Catalog from "../components/Catalog";

const CatalogPage = () => {
  return (
    <>
      <Header />
      <Catalog />
      <Footer />
    </>
  );
};

export default CatalogPage;
