import React, { memo, useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import { InputBase } from "@material-ui/core";
import InputFormat from "components/Inputs/InputFormat";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  valueRange: {
    display: "flex",
    justifyContent: "space-between"
  },
  input: {
    maxWidth: "12ch",
    padding: "4px",
    borderRadius: "8px",
    border: "1px solid #dfdfdf",
    "& input": {
      textAlign: "center"
    }
  }
});

const SliderCustom = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    width: "90%",
    margin: "0 auto",
    display: "block",
    height: "2px"
  },
  thumb: {
    width: "18px",
    height: "18px",
    border: "2px solid " + theme.palette.primary.main,
    bottom: "5px",
    background: "#fff",
    "&::after": {
      top: "50%",
      left: "50%",
      transform: "translateX(-50%) translateY(-50%)",
      borderRadius: "50%",
      width: "10px",
      height: "10px"
    },
    "&:focus, &:hover, &:active": {
      boxShadow: "none"
    },
    "& span": {
      display: "none"
    }
  },
  rail: {
    color: theme.palette.primary.light
  },

  "@media (max-width: 1024px)": {
    thumb: {
      bottom: "12px"
    }
  }
}))(Slider);

const RangeFilter = ({
  rangeMin = 0,
  rangeMax = 0,
  currentRange = [],
  onChange,
  name,
  type
}) => {
  const classes = useStyles();
  const hasSelectedCheck = currentRange.length > 0;
  const [value, setValue] = useState(
    hasSelectedCheck
      ? [Number(currentRange[0]), Number(currentRange[1])]
      : [rangeMin, rangeMax]
  );
  useEffect(() => {
    if (
      currentRange.length === 0 &&
      (value[0] !== rangeMin || value[1] !== rangeMax)
    ) {
      return setValue([rangeMin, rangeMax]);
    }

    if (value[0] >= rangeMin && value[1] <= rangeMax) {
      return;
    }

    const newValue = [rangeMin ?? 0, rangeMax ?? 0];
    setValue(newValue);

    if (currentRange.length) {
      onChange(name, undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRange.length, rangeMin, rangeMax]);

  const triggerChange = (event, newValue) => {
    if (newValue[0] === rangeMin && newValue[1] === rangeMax) {
      return onChange(name, undefined);
    }

    onChange(name, newValue);
  };

  const handleBlur = () => {
    let newValue = value;
    if (value[0] < rangeMin) {
      newValue = [rangeMin, newValue[1]];
    }
    if (value[0] > rangeMax) {
      newValue = [value[1], rangeMax];
    }
    if (value[1] > rangeMax) {
      newValue = [value[0], rangeMax];
      setValue(state => [state[0], rangeMax]);
    }
    if (value[1] < rangeMin) {
      newValue = [rangeMin, value[0]];
    }
    newValue = [Math.min(...newValue), Math.max(...newValue)];
    setValue(newValue);
    triggerChange("", newValue);
  };

  const handleInputChange = event => {
    const value = Number(event.target.value);
    if (event.target.name === "min") {
      return setValue(state => [value, state[1]]);
    }
    setValue(state => [state[0], value]);
  };

  return (
    <div className={classes.root}>
      <div className={classes.valueRange}>
        <InputBase
          name="min"
          inputComponent={type ? InputFormat : "input"}
          inputProps={{ type }}
          onChange={handleInputChange}
          onBlur={handleBlur}
          className={classes.input}
          value={value[0]}
        />
        <InputBase
          name="max"
          inputComponent={type ? InputFormat : "input"}
          inputProps={{ type }}
          onChange={handleInputChange}
          onBlur={handleBlur}
          className={classes.input}
          value={value[1]}
        />
      </div>
      <SliderCustom
        min={rangeMin ?? 0}
        max={rangeMax ?? 0}
        onChange={(e, newValue) => setValue(newValue)}
        onChangeCommitted={triggerChange}
        value={value}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
      />
    </div>
  );
};
export default memo(
  RangeFilter,
  (prev, next) =>
    prev.rangeMax === next.rangeMax &&
    prev.rangeMin === next.rangeMin &&
    prev.currentRange.length === next.currentRange.length
);
