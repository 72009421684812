import React from "react";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import { Trash } from "components/icon";
import { useRouter } from "next/router";

const useStyles = makeStyles(theme => ({
  clearFilter: {
    fontWeight: theme.typography.fontWeightMedium,
    paddingLeft: "0px"
  },
  text: {
    marginLeft: "8px"
  }
}));

const CleanFiltersDesktop = () => {
  const classes = useStyles();
  const router = useRouter();

  return (
    <IconButton
      className={classes.clearFilter}
      onClick={() => router.push("/", undefined, { shallow: true })}
    >
      <Trash />
      <Typography className={classes.text} variant="body2">
        Limpar filtros
      </Typography>
    </IconButton>
  );
};

export default CleanFiltersDesktop;
