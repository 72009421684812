import React from "react";
import Link from "next/link";
import { makeStyles, Paper } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  image: {
    width: "100%",
    borderRadius: "8px 8px 0px 0px",
    objectFit: "cover",
    height: "165px"
  },
  "@media (max-width: 599px)": {
    image: {
      width: "148px",
      height: "112px",
      borderRadius: "8px 8px 8px 8px",
      float: "left"
    }
  }
}));

const VehicleCardImage = ({ sku, href, filename, alt, ...rest }) => {
  const classes = useStyles();
  return (
    <Paper elevation={0}>
      <Link href={href}>
        <a>
          <img
            {...rest}
            className={classes.image}
            src={`${process.env.NEXT_PUBLIC_IMAGE_BASE_URL}/sm/${filename}`}
            alt={alt}
          />
        </a>
      </Link>
    </Paper>
  );
};

export default VehicleCardImage;
