import React from "react";
import {
  makeStyles,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  list: {
    "& .MuiFormControlLabel-root": {
      height: "38px"
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: theme.palette.primary.main
    },
    "& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1": {
      color: "#35353D",
      fontWeight: theme.typography.fontWeightRegular
    },
    "& span.MuiTypography-root.MuiFormControlLabel-label": {
      fontSize: "14px",
      lineHeight: "15px"
    }
  },
  particular: {
    "& span:last-of-type": {
      display: "flex",
      alignItems: "center"
    }
  },
  particularTag: {
    width: "15px",
    marginLeft: "10px"
  }
}));

const CheckboxList = ({ data, onChange, name, selectedCheck = [] }) => {
  const classes = useStyles();

  const handleChange = e => {
    const { value, checked } = e.target;
    const currentValueIsArray = Array.isArray(selectedCheck);

    if (!checked) {
      if (!currentValueIsArray) {
        return onChange(name, undefined, value);
      }

      const filteredSelection = selectedCheck.filter(item => item !== value);
      return onChange(name, filteredSelection, value);
    }

    if (currentValueIsArray) {
      return onChange(name, [...selectedCheck, value], value);
    }

    onChange(name, [selectedCheck, value], value);
  };

  if (!data) {
    return (
      <div className={classes.list}>
        <FormControl component="fieldset">
          <FormGroup>Lista de {name} vazia.</FormGroup>
        </FormControl>
      </div>
    );
  }

  return (
    <div className={classes.list}>
      <FormControl component="fieldset">
        <FormGroup>
          {data.map(item => (
            <FormControlLabel
              className={
                item.key === "Veículos de Particular" ? classes.particular : ""
              }
              key={item.key}
              name={name}
              value={item.key}
              checked={
                typeof selectedCheck === "string"
                  ? selectedCheck === item.key
                  : selectedCheck.includes(item.key)
              }
              control={<Checkbox />}
              label={
                item.label ??
                (item.key === "Veículos de Particular" ? (
                  <>
                    {item.key}
                    <img
                      src="https://objectstorage.sa-saopaulo-1.oraclecloud.com/n/loopbrasil/b/assets/o/revenda/particular-tag.svg"
                      className={classes.particularTag}
                      alt="veículos de particular"
                    />
                  </>
                ) : (
                  item.key
                ))
              }
              onChange={handleChange}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default React.memo(CheckboxList);
