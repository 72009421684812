import React, { useState } from "react";
import {
  makeStyles,
  Container,
  Grid,
  useMediaQuery,
  Fab
} from "@material-ui/core";
import { useVehicles } from "../api/getVehicles";
import OrderHeader from "./OrderHeader";
import CatalogFiltersColumn from "./CatalogFiltersColumn";
import EmptyCatalog from "./EmptyCatalog";
import InfiniteScroll from "./InfiniteScroll";
import MobileFiltersModal from "./MobileFiltersModal";
import CatalogBannerBenefits from "./CatalogBannerBenefits";
import VehicleItemCard from "components/VehicleCard/VehicleItemCard";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "30px 0 180px 0",
    position: "relative"
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: "40px"
  },
  fabFilter: {
    borderRadius: "10px",
    height: "60px",
    width: "50%",
    position: "fixed",
    left: "25%",
    bottom: "30px",
    zIndex: 1
  },
  "@media (max-width: 960px)": {
    title: {
      fontSize: "24px",
      lineHeight: "32px",
      marginBottom: "30px"
    },
    section: {
      margin: "30px 0 60px 0"
    }
  }
}));

const Catalog = () => {
  const classes = useStyles();
  const mobile = useMediaQuery("(max-width: 959px)");
  const [modalOpen, setModalOpen] = useState(false);
  const vehicles = useVehicles();

  const onModalClose = () => setModalOpen(false);

  console.log(vehicles);

  if (
    vehicles.status !== "success" &&
    (vehicles.isLoading || vehicles.isIdle)
  ) {
    return (
      <div className={classes.root}>
        <Container>
          <CatalogBannerBenefits />
          <OrderHeader totalHits={0} />
          <Grid container>
            {!mobile ? (
              <Grid item xs={12} sm={12} md={3}>
                <CatalogFiltersColumn filters={{}} />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <EmptyCatalog isLoading />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }

  if (vehicles.isError) {
    return (
      <div className={classes.root}>
        <Container>
          <CatalogBannerBenefits />
          <OrderHeader totalHits={0} />
          <Grid container>
            {!mobile ? (
              <Grid item xs={12} sm={12} md={3}>
                <CatalogFiltersColumn filters={{}} />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <EmptyCatalog isError />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }

  const { hits, total_found: totalHits, filters } = vehicles.data;

  if (!hits || hits.length === 0) {
    return (
      <div className={classes.root}>
        <Container>
          <CatalogBannerBenefits />
          <OrderHeader totalHits={0} />
          <Grid container>
            {!mobile ? (
              <Grid item xs={12} sm={12} md={3}>
                <CatalogFiltersColumn filters={{}} />
              </Grid>
            ) : (
              <Fab
                color="primary"
                variant="extended"
                aria-label="filter"
                className={classes.fabFilter}
                onClick={() => setModalOpen(!modalOpen)}
              >
                Filtrar veículos
              </Fab>
            )}
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <EmptyCatalog />
            </Grid>
          </Grid>
          <MobileFiltersModal
            onMobile={mobile}
            isOpen={modalOpen}
            filters={{}}
            onClose={onModalClose}
          />
        </Container>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Container>
        <CatalogBannerBenefits />
        <OrderHeader totalHits={totalHits.value} />
        <Grid container>
          {!mobile ? (
            <Grid item xs={12} sm={12} md={3}>
              <CatalogFiltersColumn filters={filters} />
            </Grid>
          ) : (
            <Fab
              color="primary"
              variant="extended"
              aria-label="filter"
              className={classes.fabFilter}
              onClick={() => setModalOpen(!modalOpen)}
            >
              Filtrar veículos
            </Fab>
          )}
          <Grid item xs={12} sm={12} md={9} lg={9}>
            <Grid container spacing={2}>
              {hits.map(vehicle => (
                <Grid key={vehicle._id} item xs={12} sm={4}>
                  <VehicleItemCard vehicle={vehicle._source} />
                </Grid>
              ))}
            </Grid>
            <InfiniteScroll
              isFetching={vehicles.isFetching}
              quantityOfHits={hits.length}
            />
          </Grid>
        </Grid>
        <MobileFiltersModal
          onMobile={mobile}
          isOpen={modalOpen}
          filters={filters}
          onClose={onModalClose}
        />
      </Container>
    </div>
  );
};

export default Catalog;
