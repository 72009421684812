import React from "react";
import { useRouter } from "next/router";
import { Button, Grid, useTheme } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Skeleton } from "@material-ui/lab";
import AlertMessage from "components/AlertMessage";
import { CarAlert } from "components/icon";

const EmptyCatalog = ({ isLoading, isError }) => {
  const router = useRouter();
  const theme = useTheme();

  if (isLoading) {
    return (
      <Grid container spacing={2}>
        {Array.from(new Array(9)).map((_, index) => (
          <Grid key={index} item lg={4} md={6} sm={6} xs={12}>
            <Skeleton style={{ borderRadius: 8 }} variant="rect" height={450} />
          </Grid>
        ))}
      </Grid>
    );
  }

  if (isError) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <AlertMessage
            icon={
              <ErrorOutlineIcon
                fontSize="large"
                fill={theme.palette.error.light}
              />
            }
            title="Ocorreu um erro!"
            message="Infelizmente não encontramos veículos disponíveis."
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <AlertMessage
          icon={<CarAlert fill={theme.palette.primary.light} />}
          title="OPS!"
          message={"Infelizmente não encontramos veículos para a sua busca."}
        >
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => router.push("/", undefined, { shallow: true })}
          >
            Limpar Filtros
          </Button>
        </AlertMessage>
      </Grid>
    </Grid>
  );
};

export default EmptyCatalog;
