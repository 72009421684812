export const Keys = {
  getVehicle: sku => ["getVehicle", { id: sku }],
  getCatalog: query => ["getCatalog", { query }],
  getUserOfferStatus: () => ["getUserOfferStatus"],
  getFreightSimulation: (sku, zipcode) => [
    "getFreightSimulation",
    { id: sku, zipcode: zipcode }
  ],
  getFeaturedGroups: () => ["getFeaturedGroups"],
  getOffersByAd: sku => ["getOffersByAd", { id: sku }],
  getAutomaticOffer: sku => ["getAutomaticOffer", { id: sku }],
  getBestOffer: sku => ["getBestOffer", { id: sku }],
  getUserReferral: id => ["getUserReferral", { id }],
  getOrder: uuid => ["getOrder", { id: uuid }],

  postOffer: () => ["postOffer"],
  postAutomaticOffer: () => ["postAutomaticOffer"],
  postSearchSimilarVehicles: (sku, brand) => [
    "postSearchSimilarVehicles",
    { id: sku, brand: brand }
  ],
  postFreightSimulation: () => ["postFreightSimulation"],
  passwordRecovery: () => ["passwordRecovery"],
  postResendConfirmation: () => ["postResendConfirmation"],
  postConsent: () => ["postConsent"],

  putOrderCustomer: () => ["putOrderCustomer"]
};
