import React, { memo, useCallback } from "react";
import CheckboxList from "./CheckboxList";
import ControlDropdown from "components/ControlDropdown";

const PlateFilter = ({ onChange, selectedCheck = [] }) => {
  const formatPlate = (name, checklist) => {
    const formattedChecklist = checklist.flatMap(check => check.split(","));
    onChange(name, formattedChecklist);
  };

  const formatChecks = useCallback(() => {
    return selectedCheck.flatMap((check, index) => {
      if (index % 2 !== 0) {
        return [];
      }
      return [`${check},${selectedCheck[index + 1]}`];
    });
  }, [selectedCheck]);

  const formattedSelectedCheck = formatChecks();

  return (
    <ControlDropdown title="Finais de placa" size="big">
      <CheckboxList
        selectedCheck={formattedSelectedCheck}
        data={[
          { label: "1 e 2", key: "1,2" },
          { label: "3 e 4", key: "3,4" },
          { label: "5 e 6", key: "5,6" },
          { label: "7 e 8", key: "7,8" },
          { label: "9 e 0", key: "9,0" }
        ]}
        onChange={formatPlate}
        name="placa"
      />
    </ControlDropdown>
  );
};

export default memo(PlateFilter);
